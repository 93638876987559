import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @font-face {
        font-family: 'Open Sans';
        font-weight: 300 800;
        font-display: swap;
        font-style: normal;
        src: url(/fonts/open-sans-var.woff2) format("woff2");
    }

    @font-face {
        font-family: 'Open Sans';
        font-weight: 300 800;
        font-display: swap;
        font-style: italic;
        src: url(/fonts/open-sans-italic-var.woff2) format("woff2");
    }
`;
